import { ALERTS } from 'redux/actions/Types';


const initialState = {
  alert: null,
};

const AlertReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALERTS.SHOW_ALERT:
      return { alert: { ...action.payload } };
    case ALERTS.HIDE_ALERT:
      return { alert: null };
    default:
      return state;
  }
};

export default AlertReducer;
