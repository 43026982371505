import React from 'react';
import Toast from 'components/Common/Toast';
import { hideAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';


const AppAlert = () => {
  const alert = useSelector(state => state.alerts.alert);
  const dispatch = useDispatch();
  return (
    <>
      {alert && (
        <Toast
          message={alert.message}
          type={alert.type}
          onClose={() => dispatch(hideAlert())}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        />
      )}
    </>
  );
};

export default AppAlert;
