import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import ListViewTable from 'components/Common/ListViewTable';
import OrganizationCustomizationTable
  from 'pages/OrganizationManagement/organization/pages/OrganizationCustomization/OrganizationCustomizationTable';
import PageHeader from 'components/Common/PageHeader';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { DEFAULT_CSS_PARAMETERS, DEFAULT_FONT_PARAMETER } from 'pages/OrganizationManagement/constants';
import { HelpLink } from 'BaseComponents';
import { ListControls } from 'components/Common/ListControls';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { isEmpty } from 'lodash';
import { organizationManagement } from 'utils/spaUrls';
import { useFetchCustomization } from 'hooks/organizationAPI';
import { useHistory } from 'react-router-dom';


const OrganizationCustomization = () => {
  const history = useHistory();
  const [customizationParameters, setCustomizationParameters] = React.useState([]);
  const {
    isSuccess: loaded,
    data: customizedParameters,
  } = useFetchCustomization();

  React.useEffect(() => {
    if (!loaded) {
      return;
    }
    if (isEmpty(customizedParameters)) {
      setCustomizationParameters(DEFAULT_CSS_PARAMETERS.concat(DEFAULT_FONT_PARAMETER));
      return;
    }

    setCustomizationParameters(customizedParameters);
  }, [loaded, customizedParameters]);

  const handleUpdate = () => {
    history.push(organizationManagement.organization.customization.edit);
  };

  return (
    <>
      <PageHeader
        title='Clean Room Customization'
        hideBackIcon
        breadcrumbs={[crumbs.home]}
      />
      <Grid item>
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ListControls
                createButton={{
                  onClick: handleUpdate,
                  text: 'Update Customization',
                }}
                helpLinkComp={<HelpLink path='cleanRoomCustomization' />}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid container spacing={0} direction='column'>
        <Grid item>
          <Card>
            <CardContent>
              {(!loaded) ? (
                <Skeleton width={250} height={50} />
              ) : (
                <ListViewTable list={customizationParameters}>
                  <OrganizationCustomizationTable
                    list={customizationParameters}
                  />
                </ListViewTable>
              )}

            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default OrganizationCustomization;
