import React from 'react';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { cleanRoom, snowflake } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';


const useFetchSnowflakeCliVersionAll = () => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const { isLoading, isError, isSuccess } = useQuery(
    ['SnowflakeCliVersionAllOrgs'],
    async () => axiosInstance.get(snowflake().snowflakeCliVersionAll),
    {
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
  };
};

const useFetchCleanroomCliVersion = (orgId) => {
  const dispatch = useDispatch();

  return useQuery(
    ['cliVersion', orgId],
    () => axiosInstance.get(cleanRoom(orgId).cliVersion).then(res => res.data),
    {
      refetchOnWindowFocus: false,
      onError: (err) => {
        dispatch(showAlert({
          message: err.response ? err.response.data.message ?? err.response.statusText : '',
          type: 'error',
        }));
      },
    },
  );
};

const useUpdateCleanroomCliVersion = () => {
  const { ID: orgId } = useSelector(activeOrgSelector);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    'updateCrCliVersion',
    (cleanRoomType) => axiosInstance.put(cleanRoom(orgId).cliVersionUpdate(cleanRoomType)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['cliVersion', orgId] });
        dispatch(showAlert({
          message: 'CLI Version updated successfully',
          type: 'success',
        }));
      },
      onError: (err) => {
        dispatch(showAlert({
          message: err.response ? err.response.data.message ?? err.response.statusText : '',
          type: 'error',
        }));
      },
    },
  );
};

export {
  useFetchSnowflakeCliVersionAll,
  useFetchCleanroomCliVersion,
  useUpdateCleanroomCliVersion,
};
