import DetailModal from 'components/Common/DetailModal';
import Grid from '@mui/material/Grid';
import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import { HButton } from 'BaseComponents';
import {
  ORGANIZATION_PARAMETERS_CATEGORIES,
  ORGANIZATION_PARAMETERS_NAMES,
  ORGANIZATION_PARAMETERS_VALUES,
  SUPPORTED_CLOUD,
} from './constant';
import { OrgParameterSelect, OrgParameterValueSelect } from './OrgParameterSelects';


const EditParam = ({
  open, onClose, row, updateOrgParameters,
}) => {
  const [editRowDetails, setEditRowDetails] = useState({ name: '', value: '', category: '' });

  useEffect(() => {
    setEditRowDetails(row);
  }, [row]);

  const handleValueChange = useCallback((value) => {
    const newRow = { ...editRowDetails };
    newRow.value = value;
    setEditRowDetails(newRow);
  }, [editRowDetails]);

  const handleCategoryChange = useCallback((category) => {
    const newRow = { ...editRowDetails };
    newRow.category = category;
    setEditRowDetails(newRow);
  }, [editRowDetails]);


  const onEdit = () => {
    const data = {
      parameter: {
        value: editRowDetails.value,
        category: editRowDetails.category,
      },
    };
    updateOrgParameters({ paramName: editRowDetails.name, payload: data });
    onClose();
  };

  return (
    <DetailModal
      modalHeading='Edit Parameter'
      onOpen={open}
      onClose={onClose}
    >
      <Box p={2}>
        <Grid>
          <OrgParameterSelect
            options={ORGANIZATION_PARAMETERS_NAMES}
            value={editRowDetails.name}
            disabled
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                required
                size='small'
                InputLabelProps={{ shrink: true }}
                label='Parameter Name'
                margin='normal'
                variant='outlined'
              />
            )}
          />
          <OrgParameterValueSelect
            nameParameterState={editRowDetails.name}
            options={ORGANIZATION_PARAMETERS_VALUES[editRowDetails.name] ?? []}
            multiple={editRowDetails.name === SUPPORTED_CLOUD}
            onChange={(value) => handleValueChange(value)}
            value={editRowDetails.value}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                required
                size='small'
                InputLabelProps={{ shrink: true }}
                label='Value'
                margin='normal'
                variant='outlined'
              />
            )}
          />
          <OrgParameterSelect
            options={ORGANIZATION_PARAMETERS_CATEGORIES}
            onChange={(value) => handleCategoryChange(value)}
            value={editRowDetails.category}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                required
                size='small'
                InputLabelProps={{ shrink: true }}
                label='Category'
                margin='normal'
                variant='outlined'
              />
            )}
          />
        </Grid>
      </Box>
      <Box
        display='flex'
        justifyContent='flex-end'
        gap={1}
      >
        <HButton
          onClick={onEdit}
          size='small'
          color='primary'
          variant='contained'
        >
          Save
        </HButton>
        <HButton
          onClick={onClose}
          size='small'
          variant='outlined'
        >
          Close
        </HButton>
      </Box>
    </DetailModal>
  );
};

export default EditParam;
