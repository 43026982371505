import React, { memo, useContext } from 'react';
import { ActionCellStyles } from 'BaseComponents/HDataTable/utils/styles';
import { DataContext } from 'BaseComponents/HDataTable/DataContextProvider';
import { HHeadCell } from 'BaseComponents/HDataTable/HHeadCell';
import { HSelectHeadCell } from 'BaseComponents/HDataTable/HSelectHeadCell';
import { ResizableContext } from 'BaseComponents/HDataTable/ResizableContextProvider';
import { Tooltip, Typography } from '@mui/material';
import { UnfoldLess, UnfoldMore } from '@mui/icons-material';
import { styled } from '@mui/material/styles';


const StyledTh = styled('th')(ActionCellStyles);

export const HHeadRow = memo(() => {
  const {
    actionMenu,
    actionButton,
    columns,
    tableHasSubRows,
    expandAll,
    collapseAll,
    allExpanded,
  } = useContext(DataContext);
  const { isTableFullSize } = useContext(ResizableContext);

  return (
    <tr>
      {tableHasSubRows && (
        <th
          style={{ cursor: 'pointer', width: '44px' }}
          onClick={allExpanded ? collapseAll : expandAll}
        >
          {allExpanded ? (
            <Tooltip title='Collapse All'>
              <UnfoldLess />
            </Tooltip>
          ) : (
            <Tooltip title='Expand All'>
              <UnfoldMore />
            </Tooltip>
          )}
        </th>
      )}

      <HSelectHeadCell />

      {columns.map(({ field }) => (
        <HHeadCell key={`head-cell-${field}`} field={field} />
      ))}

      {(actionMenu || actionButton) && (
        <>
          <StyledTh isTableFullSize={isTableFullSize}>
            {isTableFullSize && <div className='shadow' />}

            <Typography variant='span' className='text'>
              Actions
            </Typography>
          </StyledTh>
          {!isTableFullSize && <th />}
        </>
      )}
    </tr>
  );
});

HHeadRow.displayName = 'HHeadRow';
