import { ALERTS } from 'redux/actions/Types';


export const showAlert = payload => ({
  type: ALERTS.SHOW_ALERT,
  payload,
});

export const hideAlert = () => ({
  type: ALERTS.HIDE_ALERT,
});
