import * as spaUrls from 'utils/spaUrls';
import HelpIcon from '@mui/icons-material/Help';
import React from 'react';
import { HIconButton, LinkComponent } from 'BaseComponents';
import { Tooltip } from '@mui/material';


const Help = () => (
  <LinkComponent
    to={spaUrls.redirect.habuHelp}
    component={({ navigate }) => (
      <HIconButton onClick={navigate} sx={{ margin: '0 8px' }}>
        <Tooltip title='Help' placement='bottom'>
          <HelpIcon />
        </Tooltip>
      </HIconButton>
    )}
  />
);

export default Help;
