import React, { memo, useContext } from 'react';
import { DataContext } from 'BaseComponents/HDataTable/DataContextProvider';
import { HActionCell } from 'BaseComponents/HDataTable/HActionCell';
import { HBodyCell } from 'BaseComponents/HDataTable/HBodyCell';
import { HSelectBodyCell } from 'BaseComponents/HDataTable/HSelectBodyCell';
import { HSubRowIndicatorCell } from 'BaseComponents/HDataTable/HSubRowIndicatorCell';
import { v4 as uuidGen } from 'uuid';


export const HBodyRow = memo(({ row, parentRow, isSubRow }) => {
  const {
    multiSelectField, columns, subRowsProp, expandedRows, toggleRow, tableHasSubRows,
  } = useContext(DataContext);
  const isRowHasSubRows = !!row?.[subRowsProp]?.length;
  const addEmptyCell = tableHasSubRows && !isRowHasSubRows && !isSubRow;
  const isRowExpanded = expandedRows[row.ID];
  const actionCellRow = parentRow
    ? { ...parentRow, subRow: row }
    : { ...row, hasSubRows: !!row[subRowsProp]?.length };

  return (
    <>
      <tr
        style={{
          cursor: isRowHasSubRows ? 'pointer' : 'inherit',
        }}
        onClick={() => isRowHasSubRows && toggleRow(row.ID)}
      >
        {isRowHasSubRows && <HSubRowIndicatorCell showSubRows={isRowExpanded} />}
        {/* To offset collapse/expand column when there are no subRows */}
        {(isSubRow || addEmptyCell) && <td />}

        <HSelectBodyCell row={row} />

        {columns.map(({ component: Component, field, ...restFields }) => (
          <HBodyCell
            key={`body-cell-${row[multiSelectField] ?? uuidGen()}-${field}`}
            row={row}
            field={field}
            {...restFields}
          >
            {Component && <Component row={row} />}
          </HBodyCell>
        ))}

        {/* To offset actions column when there are no subRows */}
        {addEmptyCell ? <td /> : <HActionCell row={actionCellRow} />}
      </tr>

      {isRowExpanded
        && row?.[subRowsProp]?.map((subRow) => (
          <HBodyRow
            row={subRow}
            parentRow={row}
            key={`body-row-${subRow.ID ?? uuidGen()}`}
            isSubRow
          />
        ))}
    </>
  );
});

HBodyRow.displayName = 'HBodyRow';
