import AccountAndOrgSwitcher from 'components/PrimaryHeader/AccountAndOrgSwitcher';
import Box from '@mui/material/Box';
import Help from './Help';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React from 'react';
import UserTools from './UserTools';
import { HIconButton, MenuDropdown } from 'BaseComponents';
import { styled } from '@mui/material/styles';


const StyledUserIcon = styled(MoreHorizIcon)`
  height: 22px;
  width: 22px;
`;

const MobileUserTools = ({ items }) => {
  const [anchorEl, setAnchorEl] = React.useState();
  const open = Boolean(anchorEl);


  return (
    <>
      <HIconButton data-testid='more-button-logout' onClick={e => setAnchorEl(e.currentTarget)} active={open.toString()}>
        <StyledUserIcon />
      </HIconButton>
      <MenuDropdown
        handleClose={() => setAnchorEl(null)}
        anchor={anchorEl}
        open={open}
      >
        <Box sx={{ p: 1, borderTop: 'solid #97a6be 1px' }}>
          <AccountAndOrgSwitcher />
        </Box>

        <Box sx={{ p: 1, borderTop: 'solid #97a6be 1px' }}>
          <Help />
          <UserTools items={items} />
        </Box>

      </MenuDropdown>
    </>
  );
};

export default MobileUserTools;
