export const ActionCellStyles = ({ isTableFullSize }) => ({
  width: '1%',
  whiteSpace: 'nowrap',
  backgroundColor: '#fff',
  position: isTableFullSize ? 'sticky' : 'relative',
  right: 0,

  'th&': {
    position: isTableFullSize ? 'sticky' : 'relative',
    alignItems: 'baseline',
  },

  'td&': {
    textAlign: 'right',
    'tr:hover &': {
      backgroundImage: 'linear-gradient(to right, transparent , rgb(245 245 245) 30%)',
    },

    '& > *:not(:last-child)': {
      marginRight: 10,
    },
  },

  'tbody tr:hover &': {
    backgroundColor: 'rgb(245 245 245)',
  },
});
