import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import React, { memo } from 'react';
import Select from '@mui/material/Select';
import clsx from 'clsx';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const HMultipleSelect = memo(({
  label,
  menuItems,
  value,
  onChange,
  ...props
}) => {
  const isAllSelected = Array.isArray(value) && value.length === menuItems.length;

  const handleOnChange = (event) => {
    const newValue = event.target.value;
    if (onChange && onChange instanceof Function) {
      if (newValue[newValue.length - 1] === 'all') {
        onChange(value.length === menuItems.length ? [] : menuItems);
        return;
      }
      onChange(newValue);
    }
  };

  return (
    <>
      <FormControl {...props}>
        <InputLabel id='multiple-checkbox-label' sx={{ typography: 'body2' }}>{label}</InputLabel>
        <Select
          labelId='multiple-checkbox-label'
          id='multiple-checkbox'
          label={label}
          multiple
          value={value}
          onChange={handleOnChange}
          renderValue={(selected) => selected.join(', ')}
          sx={{ typography: 'body2' }}
          MenuProps={MenuProps}
        >
          <MenuItem
            key='select-all'
            value='all'
            className={clsx({
              [menuItemClasses.selected]: isAllSelected,
            })}
            disabled={menuItems.length === 0}
          >
            <Checkbox
              checked={isAllSelected}
              indeterminate={
              value.length > 0 && value.length < menuItems.length
            }
            />
            <ListItemText
              primary='ALL'
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
          <Divider />
          {menuItems.map((item) => (
            <MenuItem key={item} value={item}>
              <Checkbox checked={value ? value.indexOf(item) > -1 : false} />
              <ListItemText
                primary={item}
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
});

HMultipleSelect.displayName = 'HMultipleSelect';

export default HMultipleSelect;
