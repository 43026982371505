
// Organization Parameters list
export const CONNECTOR = 'CONNECTOR';

export const SUPPORTED_CLOUD = 'SUPPORTED_CLOUD';

export const ORGANIZATION_PARAMETERS_NAMES = [SUPPORTED_CLOUD, CONNECTOR];

export const ORGANIZATION_PARAMETERS_VALUES = {
  [SUPPORTED_CLOUD]: ['AWS', 'GCP', 'AZURE'],
  [CONNECTOR]: ['LINKEDIN', 'MICROSOFT', 'META'],
};

export const ORGANIZATION_PARAMETERS_CATEGORIES = ['TEE'];
