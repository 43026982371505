import { useState } from 'react';


export const useRowSelect = (multiSelectField) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedList, setSelectedList] = useState({});

  const handleOnSelectRow = (checked, rows, type) => {
    if (!checked) {
      setSelectAll(false);
    }

    if (type === 'SELECT_ALL') {
      if (!checked) {
        setSelectedList({});
        return;
      }
      else {
        setSelectAll(true);
      }
    }

    setSelectedList((oldSelectList) => {
      const newSelectList = rows.reduce((result, row) => {
        const selectedRow = row[multiSelectField];
        if (!checked) {
          const { [selectedRow]: removeRowInfo, ...rest } = result;

          return {
            ...rest,
          };
        }

        return {
          ...result,
          [selectedRow]: row,
        };
      }, {
        ...oldSelectList,
      });

      return newSelectList;
    });
  };

  return {
    selectAll,
    setSelectAll,
    selectedList,
    handleOnSelectRow,
  };
};
