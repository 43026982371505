import Grid from '@mui/material/Grid';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import orderBy from 'lodash/orderBy';
import { Typography } from '@mui/material';
import { habuColors } from 'Theme';


const flowNodeRunParameters = (parameters, updateNodeRunParam) => (
  <>
    {parameters.map(p => (
      <>
        {orderBy(p.nodeRunParameters, ['index'], ['asc']).map(nodeParam => (
          <TableRow key={nodeParam.ID}>
            <TableCell>{p.nodeID}</TableCell>
            <TableCell>{nodeParam.displayName}</TableCell>
            <TableCell>{nodeParam?.note ?? ''}</TableCell>
            <TableCell>
              <TextField
                fullWidth
                helperText={nodeParam.displayName}
                margin='dense'
                onChange={e => updateNodeRunParam({
                  e,
                  nodeID: p.nodeID,
                  nodeParam,
                })}
                required
                type={nodeParam.type}
                value={nodeParam.value || ''}
                variant='outlined'
              />
            </TableCell>
          </TableRow>
        ))}
      </>
    ))}
  </>
);

const FlowRunForm = ({
  runDetails, updateRunDetails, flowRunTimeParameters, updateNodeRunParam,
}) => {
  const showRunParamTable = flowRunTimeParameters.some(item => item.nodeRunParameters
    && item.nodeRunParameters.length > 0);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          data-testid='report-flow-text-field'
          fullWidth
          label='Run Name'
          margin='normal'
          onChange={e => updateRunDetails({
            ...runDetails,
            name: e.target.value,
          })}
          required
          type='text'
          value={runDetails.name || ''}
          variant='outlined'
        />
      </Grid>
      {showRunParamTable && (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Node ID</TableCell>
              <TableCell>
                <Typography variant='subtitle2' sx={{ color: habuColors.neutral['60'] }}>
                  Parameter
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='subtitle2' sx={{ color: habuColors.neutral['60'] }}>
                  Note
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='subtitle2' sx={{ color: habuColors.neutral['60'] }}>
                  Value
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {flowNodeRunParameters(
              flowRunTimeParameters,
              updateNodeRunParam,
              runDetails,
            )}
          </TableBody>
        </Table>
      </TableContainer>
      )}
    </Grid>
  );
};

export default FlowRunForm;
