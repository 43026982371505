import FlowRunForm from 'pages/Flow/FlowRun/FlowRunForm';
import FormControl from '@mui/material/FormControl';
import PermissionService from 'components/Common/PermissionService';
import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import { FlowContext } from 'pages/Flow/FlowContextProvider';
import { FlowRunContext } from 'pages/Flow/FlowRun/FlowRunProvider';
import { HDialog, HDialogContent } from 'BaseComponents';
import { HTableSkeleton } from 'BaseComponents/HDataTable/HTableSkeleton';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { productPerm } from 'utils/appConstants';
import { useFetchFlowRunParameters, useSubmitFlowRun } from 'hooks/Flow/flowAPI';
import { useGetDPAccountantView } from 'api/cleanrooms/useGetDPAccountantView';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';


const FlowRunDialog = () => {
  const { crId } = useParams();
  const {
    dataContextRef,
  } = useContext(FlowContext);
  const { isOpen, closeModal, modalDetails } = React.useContext(FlowRunContext);
  const [runDetails, setRunDetails] = React.useState({ name: '', flowParameters: [] });
  const [transformedData, setTransformedData] = React.useState([]);

  const { ID: orgID } = useSelector(activeOrgSelector);
  const crDetail = useSelector(state => state.cleanRooms.detail);

  const isDifferentialPrivacyEnabled = PermissionService
    .isProductEnabled([productPerm.DifferentialPrivacy]);

  const {
    isLoading: runParamAPILoading,
    isSuccess: runParamAPISuccess,
    data,
  } = useFetchFlowRunParameters(
    { crFlowId: modalDetails?.ID, crId },
  );
  const {
    refetch: refetchBudgetAccountant,
  } = useGetDPAccountantView(orgID, crId, false);
  const {
    mutate,
    isSuccess: flowRunAPISuccess,
    reset: resetFlowRunMutation,
  } = useSubmitFlowRun({ flowId: modalDetails?.ID, crId });
  const transformData = (d) => d.map(item => ({
    ...item,
    nodeRunParameters: item.nodeRunParameters.map(param => ({
      ...param,
      value: '', // Add the "Value" key with an empty string value
    })),
  }));

  // Use useEffect to update transformedData when runParamAPISuccess or data changes
  React.useEffect(() => {
    if (runParamAPISuccess) {
      const newTransformedData = transformData(data?.data.flowRunParameters);
      setTransformedData(newTransformedData);
    }
    else {
      setTransformedData([]);
    }
  }, [runParamAPISuccess, data]);

  if (modalDetails === null) return null;

  const handleClose = () => {
    closeModal();
    setRunDetails({ name: '', flowParameters: [] });
  };
  const handleSubmit = () => {
    const transformedParameters = transformedData.map(item => {
      const keyValueParameters = {};

      // TODO:  what happens if they have the same name? this is an API bug
      item.nodeRunParameters.forEach(param => {
        const { name, value } = param;
        keyValueParameters[name] = value;
      });

      return {
        nodeID: item.nodeID,
        parameters: keyValueParameters,
      };
    });
    const payload = { cleanRoomFlowRun: { ...runDetails, flowParameters: transformedParameters } };
    mutate(payload);
  };

  const handleNodeRunParamFormFieldChange = ({ e, nodeID, nodeParam }) => {
    const { value } = e.target;
    const { ID: selectedParamID, name: selectedParamName } = nodeParam;
    const updatedData = transformedData.map(item => {
      if (item.nodeID === nodeID) {
        const updatedNodeRunParameters = item.nodeRunParameters.map(param => {
          const enabledStartDate = selectedParamName === 'START_DATE' && param.name === 'START_DATE';
          const enabledEndDate = selectedParamName === 'END_DATE' && param.name === 'END_DATE';
          const enabledDateParameters = selectedParamName === 'START_DATE' || selectedParamName === 'END_DATE';

          if (param.ID === selectedParamID) {
            if (enabledStartDate && enabledDateParameters) {
              return { ...param, value };
            }

            if (enabledEndDate && enabledDateParameters) {
              return { ...param, value };
            }

            if (!enabledEndDate && !enabledStartDate && !enabledDateParameters) {
              return {
                ...param,
                value,
              };
            }
          }

          return param;
        });

        return {
          ...item,
          nodeRunParameters: updatedNodeRunParameters,
        };
      }
      return item;
    });

    setTransformedData(updatedData);
  };

  if (flowRunAPISuccess) {
    resetFlowRunMutation();
    setRunDetails({ name: '', flowParameters: [] });
    dataContextRef.current.refreshListData();
    if (isDifferentialPrivacyEnabled && crDetail?.cleanRoomPrivate) {
      refetchBudgetAccountant();
    }
    closeModal();
  }
  return (
    <HDialog
      onClose={handleClose}
      onSubmit={handleSubmit}
      open={isOpen}
      title={(
        <Typography variant='h3'>
          New Flow Run
        </Typography>
    )}
      cancelText='Cancel'
      submitText='Create Run'
      maxWidth='md'
      fullWidth
    >
      <HDialogContent dividers>
        <FormControl fullWidth>
          {runParamAPILoading
            ? (<HTableSkeleton />)
            : (
              <FlowRunForm
                runDetails={runDetails}
                flowRunTimeParameters={transformedData}
                updateRunDetails={setRunDetails}
                updateNodeRunParam={handleNodeRunParamFormFieldChange}
              />
            )}
        </FormControl>
      </HDialogContent>
    </HDialog>
  );
};

export default FlowRunDialog;
