import Dialog from '@mui/material/Dialog';
import React from 'react';
import Typography from '@mui/material/Typography';
import {
  HButton,
  HDialogActions,
  HDialogContent,
  HDialogTitle,
  HSpinner,
} from 'BaseComponents';


const ConfirmationDialog = (
  {
    open,
    title,
    message,
    loading,
    error,
    onCancel,
    onConfirm,
    confirmActionLabel = 'Confirm',
  },
) => (
  <Dialog
    open={open}
    onClose={onCancel}
    fullWidth
    maxWidth='xs'
    aria-labelledby='confirmation-dialog'
    aria-describedby='confirmation-dialog'
  >
    <HDialogTitle onClose={onCancel}>
      {title}
    </HDialogTitle>
    <HDialogContent>
      <Typography
        variant='body2'
        data-testid='confirm-message'
        sx={{ whiteSpace: 'pre-line' }}
      >
        {message}
      </Typography>
      {error && (
      <Typography color='error'>
        {error}
      </Typography>
      )}
    </HDialogContent>
    <HDialogActions>
      {onCancel && (
        <HButton variant='outlined' onClick={onCancel} size='small' data-testid='cancel-button'>
          Cancel
        </HButton>
      )}
      <HButton
        variant='contained'
        color='primary'
        size='small'
        onClick={onConfirm}
        endIcon={loading ? <HSpinner isButton /> : null}
        disabled={loading}
        data-testid='confirm-button'
      >
        {confirmActionLabel}
      </HButton>
    </HDialogActions>
  </Dialog>
);

export default ConfirmationDialog;
