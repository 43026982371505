import React from 'react';
import Switch from 'components/Common/Switch';
import {
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';


const StyledCell = styled(TableCell)(() => (
  {
    'font-weight': 'bold',
  }
));

const CleanRoomTypesTable = ({ list, cleanRoomTypes, setCleanRoomType }) => {
  const onToggle = (cleanRoomType, isChecked) => {
    if (!isChecked) {
      setCleanRoomType(cleanRoomTypes.filter(i => i !== cleanRoomType.ID));
    }
    else {
      setCleanRoomType([...cleanRoomTypes, cleanRoomType.ID]);
    }
  };

  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell width='5%' />
          <StyledCell>
            Clean Room Types
          </StyledCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list.map(row => (
          <TableRow key={row.ID}>
            <TableCell>
              <Switch
                checked={cleanRoomTypes?.indexOf(row.ID) !== -1}
                value={row.ID}
                onChange={e => onToggle(row, e.target.checked)}
              />
            </TableCell>
            <TableCell>
              {row.displayName}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CleanRoomTypesTable;
