import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import Snackbar from '@mui/material/Snackbar';


const Toast = ({
  message, type, ...props
}) => {
  const [open, setOpen] = React.useState(true);

  const action = (
    <IconButton
      size='small'
      aria-label='close'
      color='inherit'
      onClick={() => {
        setOpen(false);
        props.onClose?.();
      }}
    >
      <CloseIcon fontSize='small' />
    </IconButton>
  );

  React.useEffect(() => { setOpen(true); }, [message]);

  return (
    <Snackbar
      sx={{ maxWidth: 550, whiteSpace: 'pre-line' }}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      autoHideDuration={type === 'success' ? 3000 : 10000}
      onClose={() => setOpen(false)}
      open={open}
      {...props}
    >
      <Alert
        data-testid='toast-alert'
        action={action}
        severity={type}
        variant='filled'
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
